<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习报告</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div class="contain">
            <div class="maintext">实习计划:</div>
            <el-input class="elinput" size="small" v-model="searchvalue" placeholder="请输入内容" clearable>
              <i class="el-icon-search el-icon_search" slot="suffix"> </i>
            </el-input>
          </div>
          <div class="contain">
            <div class="maintext">提交状态:</div>
            <el-select class="elinput" size="small" v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <div class="bt restbt">重置</div>
          <div class="bt searchbt">查询</div>
        </div>
      </div>

      <div class="main-contain">
        <div class="contain">
          <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#FFFFFF',
            }" :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }">
            <el-table-column prop="date" label="实习计划"> </el-table-column>
            <el-table-column prop="name" label="实习形式"> </el-table-column>
            <el-table-column prop="template" label="报告模板">
            </el-table-column>
            <el-table-column prop="time" label="参与时间"> </el-table-column>
            <el-table-column prop="state" label="实习状态"> </el-table-column>
            <el-table-column prop="state1" label="实习状态"> </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="item-right">
                  <div class="bt" @click="checkbt(scope.row)">查看</div>
                  <div class="line2"></div>
                  <div class="bt" @click="tosubmitreport(scope.row)">提交</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="elpagination">
            <div class="elpagination-title">共{{ total }}条</div>
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="changePahe">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      total: 0,
      searchvalue: "",
      tableData: [],
      options: [],
      value: "",
    };
  },
  computed: {},
  methods: {
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        params: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    todetail() {
      this.$router.push({
        path: "/home/personalcenter/interactiveqadetails",
      });
    },
    tosubmitreport() {
      this.$router.replace({
        path: "/home/personalcenter/submitreport",
      });
    },
    changePahe(val){
       
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0773fc;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 88px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #0773fc;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    height: 1008px;
    background: #fcfcfc;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    .searchcontain {
      height: 56px;
      background: #f7f7f7;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .contain {
          margin-right: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .maintext {
            margin-right: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }
          .elinput {
            width: 200px;
            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 64px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #0773fc;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
        }
        .restbt {
          margin-right: 10px;
          background: #0773fc;
          color: white;
        }
       
      }
    }
    .main-contain {
      margin-top: 40px;
      margin-left: 10px;
      margin-right: 10px;
      height: 200px;
      width: 100%;
      position: relative;

      .contain {
        position: absolute;
        width: 100%;
        height: 100%;
        .Table {
          width: 100%;
          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .title {
              margin-left: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
          .item-middle {
            .title {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;
            .bt {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #0773fc;
              user-select: none;
              cursor: pointer;
            }
            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }
        .elpagination {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .elpagination-title {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
